@font-face {
  font-family: 'Ubuntu';
  src: url('./fonts/Ubuntu-Regular.ttf')  format('truetype'),
       url('./fonts/Ubuntu-Bold.ttf') format('truetype');

}

html {
  font: 16.5px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
