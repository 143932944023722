.nav_container {
  display: flex;
  width: 96vw;
  height: 5.2vw;
  padding: 2vw;
  justify-content: space-between;
}

.logo_container {
  flex-basis: 40%;
  margin-left: 5.5vw;
  align-self: flex-start;
}

.logo {
  height: 70%;
  width: 37.5%;
}

.menu {
  align-self: center;
  margin-right: 5vw;
  /* flex-grow: 1; */
}

.menu a:link, .menu a:visited {
  font-size: 1.6vw;
  margin: 0 1vw;
  text-decoration: none;
  color: #fff;
  border: 0;
  -webkit-transition: border 0.25s;
  transition: border 0.25s;
}

.menu a:hover,
.menu a:active {
  border-bottom: 2px solid #6de3b6;
}