.philosophy {
    display: flex;
    width: 100vw;
    height: 45vw;
    align-items: center;
    justify-content: space-evenly;
}

.philosophy_content {
    background-image: url(./images/bakcground@2x.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.flex_container {
    height: 60%;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.philosophy_content h2 {
    font-size: 2.6vw
}

.philosophy_content p {
    text-align: start;
    align-self: flex-start;
    font-size: 1.6vw
}

.screen_shot {
    /* width: 40%; */
    height: 100%;
    margin-top: calc(100vw * .5 * 0.125);
}


.button, .button:link, .button:visited {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.4vw;
    padding: 1.6vw 7vw;
    cursor: pointer;
    border-radius: 4vw;
    transition: all .2s;
    align-self: flex-start;
  }

.button:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(#000, .2);
}

.button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.button:active, .button:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba(#000, .2);
}

.button_green {
    background-color: #6de3b6;
    color: #fff;
}

.button_white {
    background-color: #fff;
    color: #6de3b6;
}

.button_white::after {
    background-color: #fff;
}

.button_green::after {
    background-color: #6de3b6;
}