.container {
  display: flex;
  flex-direction: column;
  width: 98vw;
  height: 80vw;
  margin: 1vw 1vw;
}

/* 100vw height */

.container h2 {
  margin-top: 2vw;
  margin-left: 3vw;
  margin-bottom: 4vw;
  font-size: 3.52vw;
}

/* 100vw - 7vw = 93vw */

.card_info h3 {
  font-size: 2.4vw;
}

.card_info h4 {
  color: gray;
}

.card_info p {
  font-size: 1.6vw;
  margin-bottom: 1.5vw;
}

/* 93 vw height */

.card_container {
  display: flex;
  padding: 3vw;
  height: 40%;
}

/* 93 - 2 * 6 = 81vw height */

.reverse {
  flex-direction: row-reverse;
}

.card_container img {
  /* align-self: center; */
  width: 25vw;
  height: 25vw;
}

/*  91vw - 25 * 2 = 41 */

.card_info {
  flex-grow: 1;
  margin: 0 2vw;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 60vw;
}

.info_reverse {
  text-align: right;
}

.button_container {
  display: flex;
  width: 100%;
}


.facebook {
  width: 3vw;
  height: 2.75vw;
  background-image: url(./images/facebook2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.linkedIn {
  width: 3vw;
  height: 3vw;
  margin-left: .75rem;
  background-image: url(./images/linkedIn2x.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.icons_right {
   flex-direction: row-reverse;
}

.linkedIn_right {
  order: -1;
}
