.footer {
  background-image: linear-gradient(to bottom, #59bcff, #3b72ff);
  width: 100vw;
  height: 2.4rem;
  display: flex;
  flex-direction: row-reverse;
  /* padding: 3vw; */
  color: white;

}

.footer p {
  padding-right: 5vw;
  align-self: center;
}
