*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
/* 23.4em */
html {
  
  font-size: 62.5%;
}

@media only screen and (min-width: 37.5) {
  html {
    font-size: 67.5%;
  }
}

/* @media only screen and (min-width: 50em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (min-width: 56.5em) {
  html {
    font-size: 60%;
  }
} */

@media only screen and (min-width: 75em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 100%;
  }
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

