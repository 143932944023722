.container {
  display: flex;
  width: 100vw;
  height: 70vw;
  align-items: center;
  justify-content: center;
  background-color: #6de3b623;
}


.contact_form_container {
  margin-left: 4vw;
  padding-left: 4vw;
  padding-right: 4vw;
  width: 50%;
  height: 80%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5vw;
}

.field {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  font-size: 1.925vw;
  font-family: inherit;
  color: inherit;
  padding: 1.1vw 2.2vw 1.1vw
    1.1vw;
  background-color: rgba(#fff, 0.5);
  border: 0.5px solid lightgray;
  transition: all 0.3s;
  height: 30%;
}

.big {
    height: 70%;
}

/* .input:not(:last-child) {
    margin: 2rem;
} */

.input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(#000, 0.1);
  border: 0.5px solid #4791ff;
}

.input:focus:invalid {
  border: 1px solid red;
}

.input::-webkit-input-placeholder {
  color: lightgray;
}

.label {
  font-size: 1.65vw;
  margin-bottom: 1vw;
}

.email {
  grid-column-start: span 2;
  /* width: 100%; */
}

.message {
  grid-column-start: span 2;
  grid-row: 3 / span 2;
}

.message .textarea {
  flex-basis: 80%;
  height: 80%;
}

.contact_info {
  width: 45%;
  height: 60%;
  margin-right: 2.5vw;
  margin-left: 3vw;
}

.contact_info h2 {
  font-size: 3.2vw;
  margin-bottom: 1vw;
}

.contact_info p {
  font-size: 1.6vw;
}

.button,
.button:link,
.button:visited {
  align-self: center;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.6vw;
  padding: 1.5vw 7vw;
  cursor: pointer;
  border-radius: 4vw;
  transition: all 0.2s;
  align-self: flex-start;
}

.button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(#000, 0.2);
}

.button:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.button:active,
.button:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(#000, 0.2);
}

.button_blue {
  background-color: #4791ff;
  color: #fff;
}

.hidden {
    display: none;
}