
.card {
    width: 100vw;
    height: 24vw;
    z-index: -1;
    /* width: 60%;
    height: 60%; */
    /* z-index: -1; */
    margin: auto;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: space-evenly;
}

.content {
    width: 45%;
}

.content > h2 {
    font-size: 2.2vw
}

.content > p {
    font-size: 1.6vw
}

.card_icon {
    width: 20vw;
}

.card_icon_order_first {
    order: -1;
}

.card_color_green {
    background-color: #6de3b623;
}

.card_color_white {
    background-color: #fff;
}