.banner {
    background-image: linear-gradient(to right bottom, #59bcff, #3b72ff);
    display: flex;
    flex-direction: column;
}

.banner_container {
    width: 92.5;
    height: calc(100vw / 2.2);
    display: flex;
    justify-content: space-between;
    padding-left: 7.5vw;
}

.banner__main {
    color: #fff;
    font-size: 2.24vw;
    width: 40vw;
    height: calc(40vw * 1.2);
    align-self: center;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.banner__main h1 {
    margin: 0;
    font-size: 3.2vw;
}

.banner__main span {
    display: block;
}


.button, .button:link, .button:visited {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.92;
    padding: .7rem 1rem;
    cursor: pointer;
    border-radius: 2rem;
    transition: all .2s;
    align-self: flex-start;
  }

.button:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(#000, .2);
}

.button:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.button:active, .button:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 .5rem 1rem rgba(#000, .2);
}

.button_green {
    background-color: #6de3b6;
    color: #fff;
}

.button_green::after {
    background-color: #6de3b6;
}

.image__part {
    align-self: flex-end;
    width: 60vw;
}

.image__part img {
    margin-bottom: calc(-1 * 100vw / 1.75 * 0.05);
    margin-left: 2vw;
    width: 50vw;
}


@media only screen and (max-width: 20em) {
    
    .banner__main {
        width: 45vw;
        font-size: 2.34vw;
    }
    .banner__main h1 {
        font-size: 2.34vw;
    }

    .image__part {
        width: 55vw;
    }

    .button, .button:link, .button:visited {
        font-size: 2.16vw;
        padding: .5rem .6rem;
        align-self: flex-start;
    }
  }

 
  @media only screen and (min-width: 64em) {

    .banner__main {
        height: calc(40vw * 1);
        font-size: 1.6vw;
    }
    .banner__main h1 {
        font-size: 2.56vw;
    }

    .button, .button:link, .button:visited {
        align-self: flex-start;
        font-size: 1.152vw;
        /* width: 50%; */
        border-radius: 3rem;
        padding: 1.5rem 2.5rem;
    }
  } 